import Img from "gatsby-image";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { breakpoints, colors } from "../../styles/variables";

import { IoMdArrowRoundForward } from "react-icons/io";

export const Container = styled.div`
  padding: 0 1rem 0 1rem;
  display: flex;
  width: calc(100% + 2.4rem);
  margin-left: -1.2rem;

  @media (min-width: ${breakpoints.sm}) {
    padding: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const Column = styled.div`
  flex: 0 0 100%;
  padding: 0 0 3rem 0;

  @media (min-width: ${breakpoints.md}) {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 1.2rem 3rem 1.2rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
`;

export const Card = styled(Link)`
  display: block;
  position: relative;
  width: 100%;
  border: solid 1px ${colors.gray};
  text-decoration: none;
  color: #000;
  transition: filter 0.3s transform 0.5s;
  &:hover {
    color: ${colors.primary};
    .gatsby-image-wrapper {
      transform: scale(1.1);
    }
    &:after {
      width: 100%;
      left: 0;
      right: auto;
    }
  }
  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 3px;
    left: auto;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
      to right,
      ${colors.primary} 0%,
      ${colors.primary} 100%
    );
    background-color: red;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const StyledImage = styled(Img)`
  transition: transform 0.5s;
`;

export const Title = styled.h2`
  padding: 1.2rem 1.5rem 0 1.5rem;
  font-size: 1.2rem;
  color: ${colors.dark};
`;
export const ContnetContainer = styled.div`
  height: 10rem;
`;

export const Description = styled.p`
  padding: 0rem 1.5rem;
  color: ${colors.darkGray};
  font-family: "Raleway", sans-serif;
`;

export const LearnMoreWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;
`;
export const LearnMore = styled.p`
  font-size: 1rem;
  font-weight: 500;
  padding: 0 0.4rem 0 1.5rem;
`;

export const Arrow = styled(IoMdArrowRoundForward)`
  font-size: 1rem;
  color: ${colors.primary};
  margin-top: 1px;
`;
