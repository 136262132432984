import { useStaticQuery, graphql } from "gatsby";

export const useData = () => {
  const data = useStaticQuery(graphql`
    query {
      allServicesJson {
        edges {
          node {
            id
            title
            description
            imageFilePath
            beforeFilePath
            afterFilePath
          }
        }
      }
      allFile(filter: { relativeDirectory: { eq: "dental-services" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const findMainImage = (mainImages, path) => {
    if (!mainImages) return;
    return mainImages.find((mainImage) => path.includes(mainImage.node.name))
      ?.node.childImageSharp.fluid;
  };

  const result: [
    {
      id: string;
      to: string;
      title: string;
      description: string;
      mainImage: string;
      beforeImage: string;
      afterImage: string;
      path: string;
    }
  ] = data.allServicesJson.edges.map((item) => ({
    id: item.node.id,
    to: "/services/" + item.node.id,
    title: item.node.title,
    description: item.node.description.substring(0, 70) + "...",
    mainImage: findMainImage(data.allFile.edges, item.node.imageFilePath),
    beforeImage: item.beforeFilePath,
    afterImage: item.afterFilePath,
  }));

  return result;
};
